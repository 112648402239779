export enum APIUrls {
  HubUrl = '/hjsHub',
  HubChatUrl = '/aiChatHub',

  AddFeedbackDetail = 'user/AddUpdateFeedback',
  GetFeedbackList = 'user/GetFeedbackList',
  DeleteFeedback = 'user/DeleteFeedback',
  Login = 'user/Login',
  OTPVerify = 'user/OTPVerify',
  ResendOTP = 'user/ResendOTP',
  ForgotPassword = 'user/ForgotPassword',
  ResetPassword = 'user/ResetPassword',
  UploadProfileImage = 'user/UploadProfileImage',
  Profile = 'user/Profile',
  GetUserDetailsByUserId = 'user/GetUserDetailsByUserId',
  ConfirmEmail = 'user/ConfirmEmail',
  EndClientLoginOrSignUp = 'user/EndClientLoginOrSignUp',
  CheckForExistingClient = 'user/CheckForExistingClient',
  GetUserDetailsByEmail = 'user/GetUserDetailsByEmail',
  TwoFactorAuthentication = 'user/TwoFactorAuthentication',
  GetListOfClients = 'user/GetListOfClients',
  GetUserListUserCon = 'user/GetUserList',
  AddUser = 'user/AddUser',
  EditUser = 'user/EditUser',
  DeleteUser = 'user/DeleteUser',
  GetUserProfileImage = 'user/GetUserProfileImage',
  GetAvailibilityStatus = 'user/GetAvailibilityStatus',
  UpdateAvailabilityStatus = 'user/UpdateAvailabilityStatus',
  GetUserLookupList = 'user/GetUserLookupList',
  GetLegelUserLookupList = 'user/GetLegalUserLookupList',
  CheckServerAvailability = 'User/CheckServerAvailability',
  ValidateJwtToken = 'user/ValidateJwtToken',

  GetUserList = 'Timesheet/GetUserList',
  GetClientListByMatterIds = 'Timesheet/GetClientListByMatterIds',
  StartTimer = 'Timesheet/StartTimer',
  UpdateTimerByTimesheetId = 'Timesheet/UpdateTimerByTimesheetId',
  GetUserTimerDetails = 'Timesheet/GetUserTimerDetails',
  StopTimerByTimesheetId = 'Timesheet/StopTimerByTimesheetId',
  CheckValidAllowedTime = 'Timesheet/CheckValidAllowedTime',
  AddAutomaticTimeEntry = 'Timesheet/AddAutomaticTimeEntry',
  GetDashboardData = 'Timesheet/GetDashboardData',
  GetHoursDetailsById = 'Timesheet/GetHoursDetailsById',
  GetBillableHrsList = 'Timesheet/GetBillableHrsList',
  GetHJHrsList = 'Timesheet/GetHJHrsList',
  GetMatterClientList = 'Timesheet/GetMatterClientList',
  SearchMatterClientList = 'Timesheet/SearchMatterClientList',
  GetUserListOfLegalTeam = 'Timesheet/GetUserListOfLegalTeam',
  GetTempTimeHrsList = 'Timesheet/GetTempTimeHrsList',
  GetMattersByClientId = 'Timesheet/GetMattersByClientId',
  AssignTempHourstoMatter = 'Timesheet/AssignTempHourstoMatter',
  GetAllCategories = 'Timesheet/GetAllCategories',
  GetTimeEntryTypes = 'Timesheet/GetTimeEntryTypes',
  GetMatterClientByTimeEntryType = 'Timesheet/GetMatterClientByTimeEntryType',
  GetMattersClientList = 'Timesheet/GetMattersClientList',
  GetClientByMatterId = 'Timesheet/GetClientByMatterId',
  CheckIfTimesheetLocked = 'Timesheet/CheckIfTimesheetLocked',
  AddEditManualTimeEntry = 'Timesheet/AddEditManualTimeEntry',
  GetTimeSheetManualEntryLogList = 'Timesheet/GetTimeSheetManualEntryLogList',
  GetWeeklyTimesheetList = 'Timesheet/GetWeeklyTimesheetList',
  GetTimesheetSummary = 'Timesheet/GetTimesheetSummary',
  GetIncompleteTimeSheetList = 'Timesheet/GetIncompleteTimeSheetList',
  GetTimesheetDetailsByTimesheetId = 'Timesheet/GetTimesheetDetailsByTimesheetId',
  DeleteTimesheetEntry = 'Timesheet/DeleteTimesheetEntry',
  GetLockTimeSheet = 'Timesheet/GetLockTimeSheet',
  LockUnLockTimeSheet = 'Timesheet/LockUnLockTimeSheet',
  GetTimeSheetReport = 'Timesheet/GetTimeSheetReport',
  GetTimeSheetReportListForGroupBy = 'Timesheet/GetTimeSheetReportListForGroupBy',
  GetTimeSheetReportDataForGroupBy = 'Timesheet/GetTimeSheetReportDataForGroupBy',
  GetTimeSheetGroupByList = 'Timesheet/GetTimeSheetGroupByList',
  ExportTimesheetReportToExcel = 'Timesheet/ExportTimesheetReportToExcel',
  ExportTimesheetReportToPdf = 'Timesheet/ExportTimesheetReportToPdf',
  ExportTimesheetReportToCSV = 'Timesheet/ExportTimesheetReportToCSV',
  ExportTimesheetReportGroupedByToExcel = 'Timesheet/ExportTimesheetReportGroupedByToExcel',
  ExportTimesheetReportGroupedByToPdf = 'Timesheet/ExportTimesheetReportGroupedByToPdf',
  ExportTimesheetReportGroupedByToCSV = 'Timesheet/ExportTimesheetReportGroupedByToCSV',
  TimesheetFilter = 'Timesheet/TimesheetFilter',
  GetSavedTimesheetFilterList = 'Timesheet/GetSavedTimesheetFilterList',
  GetSavedTimesheetFilterData = 'Timesheet/GetSavedTimesheetFilterData',
  CheckTimesheetFilterName = 'Timesheet/CheckTimesheetFilterName',
  DeleteTimesheetFilter = 'Timesheet/DeleteTimesheetFilter',
  GetMattersListByClientId = 'Timesheet/GetMattersListByClientId',
  GetIsTimeRounded = 'Timesheet/GetIsTimeRounded',

  GetAllClients = 'Matter/GetAllClients',
  GetAllMatters = 'Matter/GetAllMatters',
  GetStatusListForMatter = 'Matter/GetStatusListForMatter',
  AddStagingNewMatter = 'Matter/AddStagingNewMatter',
  AddDraftStagingNewMatter = 'Matter/AddDraftStagingNewMatter',
  UpdateDraftStagingNewMatter = 'Matter/UpdateDraftStagingNewMatter',
  UpdateStagingNewMatter = 'Matter/UpdateStagingNewMatter',
  GetStagingNewMatterById = 'Matter/GetStagingNewMatterById',
  GetPendingStagingNewMatter = 'Matter/GetPendingStagingNewMatters',
  GetMatterList = 'Matter/GetMatterList',
  GetMatterDetailsByMatterId = 'Matter/GetMatterDetailsByMatterId',
  GetMemberList = 'Matter/GetMemberList',
  AddRemoveMember = 'Matter/AddRemoveMember',
  GetBudgetDetails = 'Matter/GetBudgetDetails',
  Budget = 'Matter/Budget',
  GetResponsibleSolicitorList = 'Matter/GetResponsibleSolicitorList',
  GetSupervisingSolicitorList = 'Matter/GetSupervisingSolicitorList',
  GetPracticeAreaHeadPhaseList = 'Matter/GetPracticeAreaHeadPhaseList',
  GetPracticeAreaHeadPhaseListByHeadlineId = 'Matter/GetPracticeAreaHeadPhaseListByHeadlineId',
  GetClientPortalMatterList = 'Matter/GetClientPortalMatterList',
  GetMembersByMatterId = 'Matter/GetMembersByMatterId',
  SendNotes = 'Matter/SendNotes',
  MatterUpdateStatus = 'Matter/UpdateStatus',

  CheckIsWorkRequestDocumentUploaded = 'WorkRequest/CheckIsWorkRequestDocumentUploaded',
  AddUpdateWorkRequestByAdmin = 'WorkRequest/AddUpdateWorkRequestByAdmin',
  GetWorkRequestListForAdmin = 'WorkRequest/GetWorkRequestListForAdmin',
  DownloadWorkRequestDocumentByIdForAdmin = 'WorkRequest/DownloadWorkRequestDocumentByIdForAdmin',
  UploadWorkRequestDocumentsByAdmin = 'WorkRequest/UploadWorkRequestDocumentsByAdmin',

  AddUpdateWorkRequest = 'EndClient/AddUpdateWorkRequest',
  GetWorkRequestStatus = 'EndClient/GetWorkRequestStatus',
  GetWorkRequestList = 'EndClient/GetWorkRequestList',
  GetWorkRequestDetailsById = 'EndClient/GetWorkRequestDetailsById',
  DownloadWorkRequestDocumentById = 'EndClient/DownloadWorkRequestDocumentById',
  UploadWorkRequestDocuments = 'EndClient/UploadWorkRequestDocuments',

  GetClientList = 'Client/GetClientList',
  GetServicePlanList = 'Client/GetServicePlanList',
  GetHourlyRate = 'Client/GetHourlyRate',
  GetClientDetailsByKey = 'Client/GetClientDetailsByKey',
  GetClientStatus = 'Client/GetClientStatus',
  AddClient = 'Client/AddClient',
  AddStagingClient = 'Client/AddStagingClient',
  GetPendingStageClient = 'Client/GetPendingStageClient',
  GetStageClientDetails = 'Client/GetStageClientDetails',
  OnBoardStageClient = 'Client/OnBoardStageClient',
  HJClientLookup = 'Client/GetHJClientLookup',
  GetClientDetails = 'Client/GetClientDetails',
  HubSpotCompanyList = 'Client/GetHubspotClient',
  HubSpotClientContactList = 'Client/GetHubspotClientContacts',

  GetDisbursmentList = 'Disbursement/GetDisbursmentList',
  GetStatus = 'Disbursement/GetStatus',
  AddDisbursement = 'Disbursement/AddDisbursement',
  GetMatterListByClient = 'Disbursement/GetMatterListByClient',
  GetClientListForMatter = 'Disbursement/GetClientListForMatter',
  GetDisbursementCategory = 'Disbursement/GetDisbursementCategory',
  GetDisbursmentDetailsById = 'Disbursement/GetDisbursmentDetailsById',
  UpdateDisbursementDetails = 'Disbursement/UpdateDisbursementDetails',

  GetWIPTimesheetEntries = 'invoice/GetWIPTimesheetEntries',
  UpdateWIPEntriesByTimesheetId = 'invoice/UpdateWIPEntriesByTimesheetId',
  RaiseMultipleInvoicesByPlanType = 'invoice/RaiseMultipleInvoicesByPlanType',
  SendMultipleInvoices = 'invoice/SendMultipleInvoices',
  GetPaymentmethodList = 'invoice/GetPaymentmethodList',
  ResendInvoice = 'invoice/ResendInvoice',
  GetParentClientList = 'invoice/GetParentClientList',
  GetCitytPlanInvoiceListByType = 'invoice/GetCitytPlanInvoiceListByType',
  GetInvoiceDetailsById = 'invoice/GetInvoiceDetailsById',
  UpdateInvoiceDetails = 'invoice/UpdateInvoiceDetails',
  GetEnterprisePlanInvoiceListByType = 'invoice/GetEnterprisePlanInvoiceListByType',
  GetExtendedEnterprisePlanInvoceListByType = 'invoice/GetExtendedEnterprisePlanInvoceListByType',
  DownloadPdfForInvoiceAndTimesheetReport = 'invoice/DownloadPdfForInvoiceAndTimesheetReport',
  GetInvoicePreview = 'invoice/GetInvoicePreview',
  GetOnDemandPlanInvoiceList = 'invoice/GetOnDemandPlanInvoiceList',
  UpdateOnDemandInvoiceDetails = 'invoice/UpdateOnDemandInvoiceDetails',
  GetOnDemandTimesheetList = 'Timesheet/GetOnDemandTimesheetList',
  GetTimesheetPreview = 'invoice/GetTimesheetPreview',
  SendMultipleTimesheets = 'invoice/SendMultipleTimesheets',
  DownloadOnDemandTimesheet = 'invoice/DownloadOnDemandTimesheet',
  ResendOnDemandTimesheet = 'invoice/ResendOnDemandTimesheet',
  CheckInvoiceRestriction = 'invoice/CheckInvoiceRestriction',
  GetBillOnCompletionList = 'invoice/GetBillOnCompletionList',
  SetBillOnCompletionStatus = 'invoice/SetBillOnCompletionStatus',

  GetRoleTypes = 'role/GetRoleTypes',
  GetHeadlinePracticaArea = 'role/GetHeadlinePracticaArea',
  GetSubPracticeAreaByHeadlinePracticeAreaId = 'role/GetSubPracticeAreaByHeadlinePracticeAreaId',
  GetAllRoles = 'role/GetAllRoles',
  GetRoleByRoleType = 'role/GetRoleByRoleType',
  GetRoleList = 'role/GetRoleList',
  CheckRoleName = 'role/CheckRoleName',
  GetUserListByRoleId = 'role/GetUserListByRoleId',
  GetFeatureListById = 'role/GetFeatureListById',
  AddRole = 'role/AddRole',
  EditRole = 'role/EditRole',
  DeleteRole = 'role/DeleteRole',
  GetRoleDetailsByRoleId = 'role/GetRoleDetailsByRoleId',
  getRolePermissionByRoleId = 'role/getRolePermissionByRoleId',
  GetMemberTypeList = 'role/GetMemberTypeList',
  GetMemberTypeByRoleId = 'role/GetMemberTypeByRoleId',
  GetSystemConfigurations = 'role/GetSystemConfigurations',
  UpdateSystemConfigurations = 'role/UpdateSystemConfigurations',
  UpdateUserPreferences = 'user/UpdateUserPreferences',

  GetBusinessReportList = 'BusinessReporting/GetBusinessReportList',
  GetBusinessReportById = 'BusinessReporting/GetBusinessReportById',
  AddUpdateBusinessReport = 'BusinessReporting/AddUpdateBusinessReport',
  DeleteBusinessReport = 'BusinessReporting/DeleteBusinessReport',
  GetBusinessArea = 'BusinessReporting/GetBusinessArea',
  BusinessReportingVerifyDomain = 'BusinessReporting/VerifyDomain',

  // Ai Chat Urls
  GetPracticeAreaMetrics = 'AIChat/metrics/practicearea',
  GetCompanyMetrics = 'AIChat/metrics',
  AiChatAssistants = 'AiChatAssistant',

  // User Feedback
  Feedback = 'Feedback',

  // Client Rates
  Rates = 'Rate',
  RateChangeLog = 'RateChangeLog',
}
