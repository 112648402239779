{
  "name": "hjs-frontend-angularjs",
  "version": "10.17.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "postinstall": "",
    "prebuild": "npm --no-git-tag-version version patch",
    "build": "ng build",
    "build:dev": "ng build --configuration dev",
    "build:stage": "ng build --configuration stage",
    "build:uat": "ng build --configuration uat",
    "build:prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration dev",
    "lint": "eslint .",
    "lint:fix": "eslint . --fix",
    "e2e": "ng e2e",
    "prepare": "husky install",
    "pre-commit": "lint-staged",
    "format-html:staged": "prettier --write",
    "check-html-formatting:staged": "prettier --check"
  },
  "lint-staged": {
    "*.html": [
      "npm run format-html:staged",
      "npm run check-html-formatting:staged"
    ],
    "*.ts": [
      "eslint --fix"
    ]
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/animations": "^17.3.12",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.12",
    "@angular/compiler": "^17.3.12",
    "@angular/core": "^17.3.12",
    "@angular/forms": "^17.3.12",
    "@angular/localize": "^17.3.12",
    "@angular/material": "^17.3.10",
    "@angular/material-moment-adapter": "^17.3.10",
    "@angular/platform-browser": "^17.3.12",
    "@angular/platform-browser-dynamic": "^17.3.12",
    "@angular/router": "^17.3.12",
    "@azure/msal-angular": "^3.0.13",
    "@azure/msal-browser": "^3.10.0",
    "@microsoft/applicationinsights-angularplugin-js": "^15.0.1",
    "@microsoft/applicationinsights-web": "^3.0.8",
    "@microsoft/signalr": "^7.0.14",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ng-select/ng-select": "^12.0.7",
    "@popperjs/core": "^2.11.2",
    "angular-svg-icon": "^13.0.0",
    "bootstrap": "^4.6.2",
    "crypto-js": "^4.1.1",
    "file-saver": "^2.0.5",
    "jquery": "^3.6.4",
    "lint-staged": "^15.2.2",
    "lodash": "^4.17.21",
    "marked": "^12.0.0",
    "moment": "^2.29.4",
    "moment-timezone": "^0.5.34",
    "nanoid": "^5.0.7",
    "ng2-charts": "^6.0.1",
    "ng2-pdf-viewer": "^9.0.0",
    "ngx-markdown": "^17.2.1",
    "ngx-skeleton-loader": "^9.0.0",
    "ngx-toastr": "^18.0.0",
    "ngx-trim-directive": "^2.0.0",
    "prismjs": "^1.28.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.3.1",
    "vest": "^5.4.0",
    "zone.js": "^0.14.4"
  },
  "devDependencies": {
    "@angular-eslint/builder": "^17.5.2",
    "@angular-eslint/eslint-plugin": "^17.5.2",
    "@angular-eslint/eslint-plugin-template": "^17.5.2",
    "@angular-eslint/schematics": "^17.5.2",
    "@angular-eslint/template-parser": "^17.5.2",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.12",
    "@types/crypto-js": "^4.1.1",
    "@types/file-saver": "^2.0.5",
    "@types/jasmine": "^3.8.0",
    "@types/lodash": "^4.14.179",
    "@types/node": "^12.11.1",
    "@typescript-eslint/eslint-plugin": "^6.19.0",
    "@typescript-eslint/parser": "^6.19.0",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "husky": "^7.0.4",
    "jasmine-core": "^3.8.0",
    "karma": "^6.3.0",
    "karma-chrome-launcher": "^3.1.0",
    "karma-coverage": "^2.0.3",
    "karma-jasmine": "^4.0.0",
    "karma-jasmine-html-reporter": "^1.7.0",
    "prettier": "^3.2.5",
    "typescript": "^5.3.3"
  }
}
